import { forwardRef, HTMLProps } from 'react'

import { Button, ButtonProps } from '@mui/material'
import {
  createLink,
  LinkComponent,
  ToOptions,
  useNavigate,
} from '@tanstack/react-router'
import { isEmpty } from 'lodash'

// This is here as the tanstack router link component has terrible performance when many are loading
// This is an issue on pages, such as the RecordEditPage where we render many in the LinkedDataSection
// As such, we use a basic anchor tag instead and build the href to emulate the same behaviour as before
// There are issues open relating to this: https://github.com/TanStack/router/discussions/2011
const createHref = ({ search, to, params }: ToOptions) => {
  let url = to ?? window.location.pathname

  if (url.includes('$') && params && !isEmpty(params)) {
    Object.entries(params).forEach(([param, value]) => {
      if (typeof value !== 'number' && typeof value !== 'string') {
        return
      }

      const replacement = new RegExp(`\\$${param}`, 'g')
      url = url.replace(
        replacement,
        typeof value === 'string' ? value : value.toString()
      )
    })
  }

  if (search && !isEmpty(search)) {
    let queryString = '?'
    Object.entries(search).forEach(([param, value], index) => {
      //Only supports primitive types for now
      if (
        typeof value !== 'string' &&
        typeof value !== 'number' &&
        typeof value !== 'boolean'
      ) {
        return
      }

      if (index > 0) {
        queryString += '&'
      }

      queryString += `${param}=${encodeURIComponent(value)}`
    })

    url += queryString
  }

  return url
}

interface MuiLinkComponentProps extends ButtonProps {
  linkProps?: HTMLProps<HTMLAnchorElement>
}

const MUILinkComponent = forwardRef<HTMLAnchorElement, MuiLinkComponentProps>(
  ({ linkProps, href, ...props }, ref) => {
    const buttonInner = (
      <Button
        sx={{
          textDecoration: 'none',
          cursor: props.disabled ? 'default' : undefined,
          ...props?.sx,
        }}
        {...props}
      >
        {props.children}
      </Button>
    )

    if (props.disabled) {
      return buttonInner
    }

    return (
      <a ref={ref} {...linkProps}>
        {buttonInner}
      </a>
    )
  }
)

const RouterLinkComponent = createLink(MUILinkComponent)

export type ButtonLinkComponent = LinkComponent<typeof MUILinkComponent>

export const ButtonLink: ButtonLinkComponent = ({
  to,
  params,
  search,
  children,
  target,
  ...props
}) => {
  const navigate = useNavigate()

  return (
    <RouterLinkComponent
      linkProps={{
        onClick: (e) => {
          // This is necessary because when used inside a table cell, the table cell hijacks the click handler
          e.stopPropagation()
          e.preventDefault()

          navigate({ to, params, search })
        },
        target,
        href: createHref({ to, params, search } as ToOptions),
      }}
      {...props}
    >
      {children}
    </RouterLinkComponent>
  )
}
